<template>
  <div id="component">
    <el-row>
      <el-col :sm="16">
        <el-table
          :data="tables.serviceFeatures.data"
          :loading="loading.main"
          style="width: 100%"
          stripe
        >
          <el-table-column :label="$tc('feature')" :width="200">
            <template slot-scope="props">
              {{ props.row.featureName }}
            </template>
          </el-table-column>
          <!-- <el-table-column type="expand">
            <template slot-scope="props">
              <p>
                <strong>{{ $tc('date') }}</strong> :{{ props.row.companyServiceFeatureDate | formatToDate }}
              </p>
              <p>
                <strong>{{ $tc('due') }}</strong> :{{ props.row.companyServiceFeatureDateDue | formatToDate }}
              </p>
            </template>
          </el-table-column> -->
          <el-table-column :label="$tc('price')">
            <template slot-scope="props">
              {{
                $options.filters.formatToCurrencyWithIsoCode(
                  props.row.companyServiceFeaturePrice,
                )
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$tc('used')" :width="120">
            <template slot-scope="props">
              <span class="tag primary">
                {{ props.row.companyServiceFeatureValueUsed }} /
                {{ props.row.companyServiceFeatureValue }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Valido hasta" :width="100">
            <template slot-scope="props">
              {{ props.row.companyServiceFeatureDateDue | formatToDateCO }}
            </template>
          </el-table-column>
          <el-table-column :label="$tc('status')" :width="120">
            <template slot-scope="props">
              <span
                class="tag"
                :class="
                  props.row.companyServiceFeatureStatus === 'vencido' ||
                  props.row.companyServiceFeatureStatus === 'agotado' ||
                  props.row.companyServiceFeatureStatus === 'anulado'
                    ? 'danger'
                    : props.row.companyServiceFeatureStatus === 'pendientePago'
                    ? 'info'
                    : 'success'
                "
              >
                {{ props.row.companyServiceFeatureStatus }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :sm="8">
        <div v-cus-loading="loading.main" class="container">
          <div class="current current-plan">
            <div class="col col-select">
              <div class="card">
                <div class="card-header">
                  <h2 class="title">
                    {{ model.active.planName }}
                    ({{ $tc('active') }})
                  </h2>
                  <p class="price">
                    {{ model.active.price | formatToCurrencyWithIsoCode }}
                  </p>
                  <span class="description"></span>
                  <div class="line"></div>
                </div>
                <table class="features" width="100%">
                  <tbody>
                    <tr
                      v-for="(itemFeature, indexFeature) in getFeatures(
                        model.active.features,
                      )"
                      :key="indexFeature"
                    >
                      <td align="center">
                        <span
                          v-if="
                            itemFeature.featureType === 'access' &&
                            itemFeature.featureValue === 'true'
                          "
                          class="tag success"
                        >
                          <i class="fad fa-check fa-lg"></i>
                        </span>
                        <span
                          v-if="
                            itemFeature.featureType === 'access' &&
                            itemFeature.featureValue === 'false'
                          "
                          class="tag danger"
                        >
                          <i class="fad fa-times fa-lg"></i>
                        </span>
                        <span
                          v-if="
                            itemFeature.featureType !== 'access' &&
                            itemFeature.featureValue > 0
                          "
                          class="tag success"
                        >
                          <small>{{ itemFeature.featureValue }}</small>
                        </span>
                        <span
                          v-if="
                            itemFeature.featureType !== 'access' &&
                            itemFeature.featureValue < 0
                          "
                          class="tag primary"
                        >
                          {{ $tc('unlimited') }}
                        </span>
                        <span
                          v-if="+itemFeature.featureValue === 0"
                          class="tag danger"
                        >
                          <i class="fad fa-times fa-lg"></i>
                        </span>
                      </td>
                      <td>
                        {{ itemFeature.featureName }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <el-button
                  size="mini"
                  :loading="loading.main"
                  class="btn btn-select"
                  @click="onGoToPlanSelect()"
                >
                  <i class="fad fa-shopping-cart"> </i>
                  {{ $tc('purchasePackage', 1) }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>

/* nbxr
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'
import { sendMessage } from '@/helpers'

/* End of nbxr
-------------------------------------------------- */
export default {
  props: {
    company: { required: false, type: Object }
  },
  data() {
    return {
      model: {
        active: {
          planId: '',
          planName: '',
          price: 0,
          features: []
        },
        company: {}
      },
      tables: {
        serviceFeatures: {
          data: []
        }
      },
      loading: {
        main: false
      }
    }
  },
  methods: {
    ...mapFilters(['toCamelCase', 'toPascalCase']),
    onGoToPlanSelect() {
      sendMessage({ type: 'openUrl', url: this.urlPlanSelect })
    },
    classFeatureStatus: (featureValue, featureValueUsed) => {
      const featureValueUsedPercent = (featureValueUsed * 100) / featureValue
      if (featureValueUsedPercent > 90) {
        return 'danger'
      }
      if (featureValueUsedPercent > 80) {
        return 'warning'
      }
      if (featureValueUsedPercent > 70) {
        return 'default'
      }
      if (featureValueUsedPercent > 50) {
        return 'info'
      }

      return 'success'
    },
    getData() {
      const _this = this
      _this.loading.main = true
      _this.$http
        .get(
          `companyServiceFeatures/${_this.$route.params.companyId}/${_this.$route.params.serviceId}/findByCompanyByService`
        )
        .then(response => {
          _this.tables.serviceFeatures.data = response.body
        })
        .finally(_ => (_this.loading.main = false))
    },
    getFeatures(features) {
      if (features && +features.length) {
        return features
          .filter(
            item => item.featureIsVisible &&
              !(+item.featureValue === 0) &&
              (item.featureType === 'access'
                ? item.featureValue === 'true'
                : true)
          )
          .sort((a, b) => {
            const valueA =
              a.featureValue === 'true'
                ? 1
                : a.featureValue === 'false'
                  ? -1
                  : +a.featureValue < 0
                    ? 1000000000
                    : +a.featureValue
            const valueB =
              b.featureValue === 'false'
                ? -1
                : b.featureValue === 'true'
                  ? 1
                  : +b.featureValue < 0
                    ? 1000000000
                    : +b.featureValue

            return valueB - valueA
          })
      }

      return []
    },
    setCompany() {
      const _this = this
      if (!_this.company) {
        _this.loading.main = true
        _this.$http
          .get(
            `companies/findByIdByService/${_this.$route.params.companyId}/${_this.$route.params.serviceId}`
          )
          .then(response => {
            _this.model.company = response.body
          })
          .finally(_ => (_this.loading.main = false))
      } else {
        _this.model.company = _this.company
      }
      _this.model.active = {
        planId: _this.model.company.planId,
        planName: _this.model.company.planName,
        price: _this.model.company.companyServicePrice,
        features: _this.model.company.features
      }
    },
    showModal(modalName) {
      const _this = this
      for (const modal in this.modal) {
        _this.modal[modal] = modal === modalName
      }
    },
    sendPostMessage() {
      const height = document.getElementById('component').offsetHeight
      sendMessage({
        type: 'frame:height',
        value: height,
        source: 'company-service-feature:public'
      })
    },
    listenWindowEvents() {
      window.onload = () => {
        this.sendPostMessage()
        sendMessage({
          type: 'loading:end',
          value: false,
          source: 'company-service-feature:public'
        })
      }
      window.onresize = () => this.sendPostMessage()
    }
  },
  computed: {
    // 'plans/buy/:service/:companyId/:planId/:planMode'
    urlPlanSelect: _this => `${process.env.VUE_APP_URI_HUB}signup/select/${_this.model.company.id}/${_this.model.company.planName}/Paquete/?product=${_this.serviceCode} - ${_this.model.company.planMode} - ${_this.model.company.companyServiceFrequencyBilling} / ${_this.model.company.planMode}`,
    serviceCode: _this => {
      if (_this.$route.params.serviceId === '1') {
        return 'fe'
      }
      if (_this.$route.params.serviceId === '2') {
        return 'aliaddo'
      }
      if (_this.$route.params.serviceId === '7') {
        return 'isv'
      }
      if (_this.$route.params.serviceId === '8') {
        return 'aliaddopos'
      }
      if (_this.$route.params.serviceId === '10') {
        return 'aliaddonominafull'
      }
      if (_this.$route.params.serviceId === '11') {
        return 'aliaddonominalite'
      }
      if (_this.$route.params.serviceId === '12') {
        return 'aliaddonominaisv'
      }

      return -1
    }
  },
  mounted() {
    this.getData()
    this.setCompany()
    this.listenWindowEvents()
  },
  beforeCreate() {
    sendMessage({
      type: 'loading:start',
      value: true,
      source: 'company-service-feature:public'
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/select-plan.scss';
.col {
  width: auto;
  .features {
    margin: 2px auto;
  }
}
div ::v-deep .col.col-select {
  margin: 0px;
}
</style>
